import { useLingui } from "@lingui/react";

import { lazyLoadLocale, locales } from "@/i18n";
import { useAnalytics } from "@/view/providers/analytics-provider";
import { cn } from "@/view/utils";

export function LanguageSwitcher() {
  const analytics = useAnalytics();
  const { i18n } = useLingui();
  return (
    <>
      <h3 className="mb-2 text-sm text-brand-gray-4">
        {i18n.t("profileNavLabelLangSwitcher")}
      </h3>
      <ul
        className={cn(
          "flex gap-1 px-1 py-1 justify-between",
          "bg-brand-gray-1 rounded-md shadow-xs border"
        )}
      >
        {(Object.keys(locales) as Array<keyof typeof locales>).map((locale) => (
          <li key={locale} className="grow">
            <button
              disabled={i18n.locale === locale}
              className={cn(
                "px-3 py-2 w-full h-full",
                "flex items-center justify-center gap-1",
                "text-sm font-semibold text-brand-black",
                "transition-colors ease-in-out duration-300",
                "rounded-sm cursor-pointer",
                {
                  "bg-brand-white rounded-sm shadow-md": i18n.locale === locale,
                  "hover:text-brand-blue-1": i18n.locale !== locale,
                  "text-brand-gray-4": i18n.locale !== locale,
                }
              )}
              onClick={() => {
                analytics.collect("locale_changed");
                lazyLoadLocale(locale);
              }}
            >
              <span>{getIcon(locale)}</span>
              <span>{locale.toUpperCase()}</span>
            </button>
          </li>
        ))}
      </ul>
    </>
  );
}

type Lang = keyof typeof locales;

function getIcon(lang: Lang) {
  switch (lang) {
    case "en":
      return "🇬🇧";
    case "de":
      return "🇩🇪";
    case "pl":
      return "🇵🇱";
  }
}
