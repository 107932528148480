import { useState } from "react";

import {
  cn,
  durationToSeconds,
  padNumberWithZero,
  secondsToDuration,
} from "@/view/utils";

import { InputWrapper } from "./input-wrapper";
import { NumberInput } from "./number-input";

export function DurationInput({
  className,
  value,
  onChange,
}: {
  className?: string;
  value: number;
  onChange: (value: number) => void;
}) {
  const [hours, minutes, seconds] = secondsToDuration(value);

  function updateCycleTime(hours: number, minutes: number, seconds: number) {
    onChange(durationToSeconds(hours, minutes, seconds));
  }

  return (
    <InputWrapper className={className}>
      <NumberInput
        className="text-center"
        value={hours}
        formatter={padNumberWithZero}
        onChange={(hours) => updateCycleTime(hours, minutes, seconds)}
      />
      <span>:</span>
      <NumberInput
        className="text-center"
        value={minutes}
        formatter={padNumberWithZero}
        onChange={(minutes) => updateCycleTime(hours, minutes, seconds)}
      />
      <span>:</span>
      <NumberInput
        className="text-center"
        value={seconds}
        formatter={padNumberWithZero}
        onChange={(seconds) => updateCycleTime(hours, minutes, seconds)}
      />
    </InputWrapper>
  );
}

export function DurationInputWithFraction({
  value: defaultValue,
  onChange,
}: {
  value: number;
  onChange: (value: number) => void;
}) {
  const [value, setValue] = useState(
    convertSecondsToMinutesWithFractions(defaultValue)
  );

  function handleChange(value: string) {
    let seconds = convertMinutesWithFractionsToSeconds(value);
    if (Number.isNaN(seconds)) return;

    if (seconds < 0) {
      seconds = 0;
      value = convertSecondsToMinutesWithFractions(0);
    } else if (seconds > 3600) {
      seconds = 3600;
      value = convertSecondsToMinutesWithFractions(3600);
    }

    setValue(
      value
        .trim()
        .split(".")
        .map((v, i) => (i === 1 ? v.substring(v.length - 2, v.length) : v))
        .join(".")
    );
    onChange(seconds);
  }

  return (
    <InputWrapper className="max-w-[94px]">
      <input
        type="numeric"
        className={cn(
          "w-full",
          "border-none leading-none text-right",
          "focus:ring-0 focus:outline-hidden",
          "disabled:opacity-30 disabled:cursor-not-allowed"
        )}
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        onKeyDown={(e) => {
          if (e.key !== "ArrowUp" && e.key !== "ArrowDown") return;

          e.preventDefault();
          const step = e.key === "ArrowUp" ? 0.01 : -0.01;
          const minutes = Math.round((parseFloat(value) + step) * 100) / 100;

          if (minutes < 0) return;
          if (minutes > 60) return;

          setValue(`${minutes}`);
          onChange(convertMinutesWithFractionsToSeconds(`${minutes}`));
        }}
      />
    </InputWrapper>
  );
}

function convertMinutesWithFractionsToSeconds(value: string) {
  return Math.round(Number(value) * 60);
}

function convertSecondsToMinutesWithFractions(value: number) {
  return `${Math.round((value * 100) / 60) / 100}`;
}
