import {
  Command,
  CommandInput,
  CommandItem,
  CommandList,
  useCommandState,
} from "cmdk";
import { CheckIcon, LoaderIcon } from "lucide-react";
import { PropsWithChildren, useState } from "react";

import { Popover, PopoverContent, PopoverTrigger } from "@/view/components";

import { cn } from "../utils";
import { Button } from "./button";
import { TagItem } from "./tag";

type OnChange<T> =
  | {
      reason: "select" | "deselect";
      value: T;
    }
  | {
      reason: "create";
      value: string;
    }
  | {
      reason: "clear";
      value: null;
    };

export function Autocomplete<
  T extends { id: string; name: string; color: string },
>({
  emptyValueLabel,
  createNewItemLabel,
  isLoading,
  options,
  value,
  onChange,
}: {
  emptyValueLabel: string;
  createNewItemLabel: string;
  isLoading?: boolean;
  options: Array<T>;
  value: Array<T>;
  onChange: (args: OnChange<T>) => void;
}) {
  const [open, setOpen] = useState(false);
  const selectedIds = value.map((v) => v.id);

  return (
    <Popover
      open={open}
      onOpenChange={(newOpen) => {
        if (newOpen) setOpen(newOpen);
      }}
    >
      <PopoverTrigger asChild>
        <Button
          role="combobox"
          analyticsEvent="video_tags_selector_clicked"
          aria-expanded={open}
          className={cn(
            "border rounded-sm border-brand-gray-2",
            "bg-brand-gray-1 hover:bg-brand-gray-2",
            "px-2 py-2 h-auto min-h-[48px] justify-start"
          )}
        >
          {value.length > 0 ? (
            <ul className="grow flex gap-2 flex-wrap">
              {value.map((v) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                <li
                  key={v.id}
                  onPointerDown={(e) => e.stopPropagation()}
                  onClick={(e) => e.stopPropagation()}
                >
                  <TagItem
                    tag={v}
                    disabled={isLoading}
                    onTagRemove={() =>
                      onChange({ reason: "deselect", value: v })
                    }
                  />
                </li>
              ))}
            </ul>
          ) : (
            <span>{emptyValueLabel}</span>
          )}
          <div className="w-6 h-6 text-brand-gray-4">
            {isLoading && <LoaderIcon className="animate-spin" />}
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="p-1"
        align="start"
        onInteractOutside={() => setOpen(false)}
        onEscapeKeyDown={() => setOpen(false)}
      >
        <Command>
          <div className="mb-1">
            <CommandInput
              placeholder="Search tags"
              className={cn(
                "flex border h-10 w-full px-2 py-3 rounded-sm outline-hidden",
                "text-sm placeholder:text-muted-foreground",
                "disabled:cursor-not-allowed disabled:opacity-50"
              )}
            />
          </div>
          <CommandList className="max-h-[300px] overflow-y-auto overflow-x-hidden">
            {options.map((option) => (
              <CommandItem
                key={option.id}
                className={cn(
                  "relative flex items-center justify-between rounded-sm px-2 py-2",
                  "cursor-default select-none",
                  "text-sm outline-hidden aria-selected:bg-accent aria-selected:text-accent-foreground",
                  "data-[disabled='true']:pointer-events-none data-[disabled='true']:opacity-50"
                )}
                disabled={isLoading}
                value={option.name}
                onSelect={() => {
                  onChange({
                    reason: selectedIds.includes(option.id)
                      ? "deselect"
                      : "select",
                    value: option,
                  });
                }}
              >
                <div className="flex gap-1 items-center">
                  <div
                    className="w-3 h-3 rounded-sm mr-2"
                    style={{ backgroundColor: option.color }}
                  />
                  {option.name}
                </div>
                <CheckIcon
                  className={cn(
                    "mr-2 h-4 w-4 text-brand-blue-1",
                    selectedIds.includes(option.id)
                      ? "opacity-100"
                      : "opacity-0"
                  )}
                />
              </CommandItem>
            ))}
            <CommandItemCreate
              disabled={isLoading ?? false}
              onSelect={(value) => {
                onChange({ reason: "create", value });
              }}
            >
              {createNewItemLabel}
            </CommandItemCreate>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

function CommandItemCreate({
  children,
  onSelect,
  disabled,
}: PropsWithChildren<{
  disabled: boolean;
  onSelect: (value: string) => void;
}>) {
  const inputValue = useCommandState((state) => state.search.trim());

  if (inputValue.length === 0) return null;

  return (
    <CommandItem
      disabled={disabled}
      key={`${inputValue}`}
      value={`${inputValue}`}
      onSelect={onSelect}
      className={cn(
        "relative flex items-center justify-between rounded-sm px-2 py-2",
        "cursor-default select-none",
        "text-sm outline-hidden aria-selected:bg-accent aria-selected:text-accent-foreground",
        "data-[disabled='true']:pointer-events-none data-[disabled='true']:opacity-50"
      )}
    >
      {children}
    </CommandItem>
  );
}
