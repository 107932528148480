import { I18n } from "@lingui/core";
import { useLingui } from "@lingui/react";
import { getDay } from "date-fns";
import { GoalIcon, LoaderIcon, XIcon } from "lucide-react";
import { PropsWithChildren, useMemo, useState } from "react";

import {
  ShiftId,
  ShiftVariantId,
  ShiftVariantTarget,
  ShiftVariantTargetType,
  ShiftWithVariants,
} from "@/domain/shifts";
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from "@/view/components";
import {
  DurationInput,
  DurationInputWithFraction,
} from "@/view/components/form/duration-input";
import { ErrorMessage } from "@/view/components/form/error-message";
import { FormSection } from "@/view/components/form/form-section";
import { InputWrapper } from "@/view/components/form/input-wrapper";
import { NumberInput } from "@/view/components/form/number-input";
import {
  SuccessFeedback,
  useShowSuccessFeedback,
} from "@/view/components/success-feedback";
import { cn, formatDuration } from "@/view/utils";

import { useShiftsWithVariants } from "../line-id-settings-shifts/shifts-with-variants-provider";
import { useTargetsOverrideActions } from "./targets-override-form-provider";
import { useCreateTargetsOverrideMutation } from "./use-create-targets-override";

export function TargetsOverrideForm({
  applicableDate,
  targetType,
}: {
  applicableDate: Date;
  targetType: ShiftVariantTargetType;
}) {
  const { i18n } = useLingui();
  const shifts = useShiftsWithVariants();
  const actions = useTargetsOverrideActions();
  const mutation = useCreateTargetsOverrideMutation();

  const [shiftId, setShiftId] = useState<ShiftId | null>(
    shifts.at(0)?.id ?? null
  );
  const variantTargetsByShiftId = useMemo(
    () => getVariantTargetsByShiftId(shifts, applicableDate),
    [shifts, applicableDate]
  );
  const shiftName = useMemo(() => {
    return (shiftId && variantTargetsByShiftId[shiftId].shiftName) ?? null;
  }, [shiftId, variantTargetsByShiftId]);
  const [targetValue, setTargetValue] = useState<number | null>(
    validateTargetValue(shiftId, variantTargetsByShiftId, targetType)
  );
  const { open, showSuccessFeedback, hideSuccessFeedback } =
    useShowSuccessFeedback();

  const { title, description, targetLabel } = getFormMessages(targetType, i18n);

  if (!shiftId) {
    // No shift groups present, we should always have at least one
    return null;
  }

  return (
    <div className="p-6 flex flex-col gap-4 h-full">
      <div className="flex justify-between items-start">
        <span className="rounded-full p-3 bg-brand-gray-1 text-brand-gray-4">
          <GoalIcon />
        </span>
        <button
          type="button"
          onClick={() => actions.close()}
          className={cn(
            "p-2 rounded-md transition-colors",
            "text-brand-gray-3 hover:text-brand-gray-5",
            "hover:bg-brand-gray-1"
          )}
        >
          <XIcon />
        </button>
      </div>
      <div>
        <h3 className="text-brand-black text-lg font-bold">{title}</h3>
        <p className="text-brand-gray-5">{description}</p>
      </div>
      <div className="grow">
        <div className="flex gap-4 items-start">
          <FormSection label={i18n.t("Select shift")}>
            <ShiftGroupSelect
              options={shifts}
              value={shiftId}
              onChange={(id) => {
                const newTargetValue = validateTargetValue(
                  id,
                  variantTargetsByShiftId,
                  targetType
                );
                setShiftId(id);
                setTargetValue(newTargetValue);
              }}
            >
              {shiftName ?? i18n.t("Select shift")}
            </ShiftGroupSelect>
          </FormSection>
          {targetType !== "cycleTime" && (
            <FormSection label={targetLabel}>
              <InputWrapper className="max-w-[144px]">
                <NumberInput
                  disabled={targetValue === null}
                  className="w-full"
                  value={targetValue ?? 0}
                  onChange={(value) => {
                    if (value < 0) value = 0;
                    setTargetValue(value);
                  }}
                />
              </InputWrapper>
            </FormSection>
          )}
          {targetType === "cycleTime" && (
            <TargetCycleTimeField
              value={targetValue ?? 0}
              onChange={setTargetValue}
            />
          )}
        </div>
        {targetValue == null && (
          <p className="w-full my-4 px-3 py-2 text-sm rounded-sm bg-brand-warninig-shade text-brand-warning">
            {i18n.t(
              "Selected shift has no variants available for today. Please select another shift or add a variant applicable for today."
            )}
          </p>
        )}
      </div>
      {mutation.isError && <ErrorMessage error={mutation.error} />}
      <div className="flex gap-2 justify-stretch">
        <button
          type="button"
          className={cn(
            "grow border rounded-md p-2",
            "text-brand-white transition-colors",
            "bg-brand-blue-1 hover:bg-brand-blue-2",
            "flex gap-2 justify-center",
            "disabled:opacity-50 disabled:cursor-not-allowed"
          )}
          disabled={mutation.isPending || targetValue === null}
          onClick={() => {
            const variant = variantTargetsByShiftId[shiftId].todaysVariant;
            if (!variant || targetValue === null) return;
            const params = {
              id: variant.id,
              date: applicableDate,
              targets: {
                ...variant.targets,
                [targetType]: targetValue,
              },
            };
            mutation.mutate(params, { onSuccess: showSuccessFeedback });
          }}
        >
          {mutation.isPending && (
            <span className="animate-spin">
              <LoaderIcon />
            </span>
          )}
          {i18n.t("Set new target")}
        </button>
      </div>
      <SuccessFeedback open={open} onClose={hideSuccessFeedback}>
        {i18n.t("The shift's target override has been saved successfully.")}
      </SuccessFeedback>
    </div>
  );
}

function TargetCycleTimeField({
  value,
  onChange,
}: {
  value: number;
  onChange: (value: number) => void;
}) {
  const { i18n } = useLingui();
  const [shouldUseFractionInput, setShouldUseFractionInput] = useState(false);

  function handleChange(value: number) {
    if (value < 0) {
      value = 0;
    } else if (value > 3600) {
      value = 3600;
    }
    onChange(value);
  }

  return (
    <FormSection
      className="justify-start"
      label={i18n.t("Target tact time")}
      labelSibling={
        <label className="text-xs flex gap-2 items-center cursor-pointer text-brand-gray-4">
          <input
            type="checkbox"
            name="duration-input"
            checked={shouldUseFractionInput}
            onChange={(e) => setShouldUseFractionInput(e.target.checked)}
          />
          {i18n.t("Enter as fraction")}
        </label>
      }
    >
      <div className="flex gap-2 items-center justify-start">
        {shouldUseFractionInput ? (
          <>
            <DurationInputWithFraction value={value} onChange={handleChange} />
            <p className="whitespace-nowrap">{formatDuration(value)}</p>
          </>
        ) : (
          <DurationInput
            className="w-auto"
            value={value}
            onChange={handleChange}
          />
        )}
      </div>
    </FormSection>
  );
}

function ShiftGroupSelect({
  children,
  options,
  value,
  onChange,
}: PropsWithChildren<{
  options: Array<ShiftWithVariants>;
  value: ShiftId;
  onChange: (value: ShiftId) => void;
}>) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          analyticsEvent="target_override_dropdown_clicked"
          className={cn(
            "justify-start h-10 w-full px-3 py-2 min-w-[140px] rounded-md",
            "border border-brand-gray-3 bg-brand-white shadow-xs text-sm"
          )}
        >
          {children}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="min-w-[240px] rounded-lg">
        <DropdownMenuRadioGroup
          value={value}
          onValueChange={(value) => onChange(value as ShiftId)}
        >
          {options.map((shift) => (
            <DropdownMenuRadioItem key={shift.id} value={shift.id}>
              {shift.name}
            </DropdownMenuRadioItem>
          ))}
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

type VariantTargetsByShiftId = Record<
  ShiftId,
  {
    shiftName: string;
    todaysVariant: {
      id: ShiftVariantId;
      targets: ShiftVariantTarget;
    } | null;
  }
>;

function getVariantTargetsByShiftId(
  shifts: Array<ShiftWithVariants>,
  applicableDate: Date
): VariantTargetsByShiftId {
  const variantTargetsByShiftId: VariantTargetsByShiftId = {};
  for (const shift of shifts) {
    variantTargetsByShiftId[shift.id] = {
      shiftName: shift.name,
      todaysVariant: null,
    };
    for (const variant of shift.variants) {
      // getDay returns 0=Sun, 1=Mon, etc, but we want Monday to be index 0
      const dayOfWeek =
        getDay(applicableDate) === 0 ? 6 : getDay(applicableDate) - 1;
      const targets = variant.weekdaysWithTargets.at(dayOfWeek);
      if (targets && variantTargetsByShiftId[shift.id].todaysVariant === null) {
        variantTargetsByShiftId[shift.id].todaysVariant = {
          id: variant.id,
          targets: { ...targets },
        };
      }
    }
  }
  return variantTargetsByShiftId;
}

function validateTargetValue(
  shiftId: ShiftId | null,
  variantTargetsByShift: VariantTargetsByShiftId,
  targetType: ShiftVariantTargetType
) {
  return (
    (shiftId &&
      variantTargetsByShift[shiftId].todaysVariant?.targets[targetType]) ??
    null
  );
}

function getFormMessages(targetType: ShiftVariantTargetType, i18n: I18n) {
  switch (targetType) {
    case "persons":
      return {
        title: i18n.t("Change your number of assigned people for today"),
        description: i18n.t("Set how many people will be working."),
        targetLabel: i18n.t("Assigned people"),
      };
    case "cycleTime":
      return {
        title: i18n.t("Change your tact time target for today"),
        description: i18n.t("Set how long your average tact time should take."),
        targetLabel: i18n.t("Target tact time"),
      };
    case "output":
    default:
      return {
        title: i18n.t("Change your output target for today"),
        description: i18n.t("Set how many units you want to produce today."),
        targetLabel: i18n.t("Target output"),
      };
  }
}
