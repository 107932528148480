import { PropsWithChildren } from "react";

import { cn } from "@/view/utils";

export function InputWrapper({
  className,
  children,
}: PropsWithChildren<{ className?: string }>) {
  return (
    <div
      className={cn(
        "flex h-10 w-full px-3 py-2",
        "rounded-md border border-brand-gray-3 bg-background shadow-xs",
        "text-sm placeholder:text-muted-foreground",
        className
      )}
    >
      {children}
    </div>
  );
}
