import { I18n } from "@lingui/core";
import { useLingui } from "@lingui/react";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { formatDistance } from "date-fns";
import {
  AlarmClockIcon,
  AlertCircleIcon,
  CircleCheckBigIcon,
  RefreshCwIcon,
  XIcon,
} from "lucide-react";
import { PropsWithChildren } from "react";

import { Notification, NotificationRule } from "@/domain/notification";
import { localeForDateFns } from "@/i18n";
import {
  BellIcon,
  Button,
  Dialog,
  DialogOverlay,
  DialogPortal,
  DialogTrigger,
  SpinnerIcon,
} from "@/view/components";
import { cn, isManualLine } from "@/view/utils";

import { LevelsCtxValue, useLevels } from "./levels-provider";
import { useNotifications } from "./use-notifications";
import { useSelectedLine } from "./use-selected-line";

export function NotificationsDrawer() {
  const { i18n } = useLingui();
  const { state, actions } = useNotifications();
  const line = useSelectedLine();

  if (isManualLine({ line })) {
    return null;
  }

  return (
    <>
      <Dialog
        open={state.drawerOpen}
        onOpenChange={(open) => {
          if (open) {
            actions.openDrawer();
          } else {
            actions.closeDrawer();
          }
        }}
      >
        <DialogTrigger
          disabled={!state.isSuccess}
          className={cn(
            "text-brand-blue-1 hover:bg-brand-gray-1 focus:outline-hidden rounded-full",
            "flex items-center justify-center w-10 h-10 relative",
            {
              "bg-brand-warninig-shade": state.isFailure,
            }
          )}
        >
          {state.isLoading && (
            <>
              <SpinnerIcon className="h-4 w-4" />
            </>
          )}
          {state.isFailure && (
            <>
              <AlertCircleIcon className="h-4 w-4 text-brand-warning" />
            </>
          )}
          {state.isSuccess && (
            <>
              <BellIcon className="h-4 w-4" outlined={state.hasNoUnread} />
              {state.showCountBadge && (
                <div
                  className={cn(
                    "inline-flex items-center justify-center",
                    "text-xs font-bold text-white bg-red-500 rounded-full",
                    "absolute -top-1 -right-1 w-4 h-4"
                  )}
                >
                  {state.unreadCount}
                </div>
              )}
            </>
          )}
        </DialogTrigger>
        <DialogPortal>
          <DialogOverlay className="z-20" />
          <DialogPrimitive.Content
            onClick={(e) => e.stopPropagation()}
            className={cn(
              "fixed z-30 right-0 top-0 bottom-0 overflow-hidden",
              "flex flex-col w-[540px]",
              "bg-brand-white shadow-lg transition ease-in-out",
              "data-[state=open]:animate-in data-[state=open]:duration-500 data-[state=open]:slide-in-from-right",
              "data-[state=closed]:animate-out data-[state=closed]:duration-500 data-[state=closed]:slide-out-to-right"
            )}
          >
            <header
              className={cn("flex justify-between items-baseline", "px-8 py-4")}
            >
              <h3 className="text-xl font-semibold">
                {i18n.t("Notifications")}
              </h3>
              <Button
                analyticsEvent="notifications_show_all_clicked"
                className={cn("text-brand-gray-3 focus:outline-hidden")}
                onClick={() => actions.closeDrawer()}
              >
                <XIcon className="w-6 h-6" />
              </Button>
            </header>
            <div className="border-b mx-8" />
            {!state.isFetching && state.notifications.length === 0 && (
              <div className="grow flex justify-center items-center">
                <div className="text-center">
                  <h4 className="text-brand-black text-base font-semibold">
                    {i18n.t("inboxDrawerNotFoundTitle")}
                  </h4>
                  <p className="text-brand-gray-4 text-sm">
                    {i18n.t("inboxDrawerNotFoundDescription")}
                  </p>
                </div>
              </div>
            )}
            <div className="overflow-auto px-8">
              {state.notifications.length > 0 && (
                <ul>
                  {state.notifications.map((it) => (
                    <li key={it.id}>
                      <NotificationItem
                        notification={it}
                        isSnoozing={state.isSnoozing(it.id)}
                        isMarkingAsRead={state.isMarkingAsRead(it.id)}
                        onRead={() => actions.readNotification(it)}
                        onSnooze={() => actions.snoozeNotification(it)}
                      />
                    </li>
                  ))}
                </ul>
              )}
              {(state.isFetching || state.isFetchingNextPage) && (
                <>
                  {[...Array(3)].map((_, i) => (
                    <div
                      key={i}
                      className="animate-pulse my-2 py-6 flex w-full gap-4"
                    >
                      <div className="w-14 h-14 bg-brand-gray-2" />
                      <div className="flex-1">
                        <div className="flex justify-between mb-2">
                          <span className="w-24 h-4 bg-brand-gray-2" />
                          <span className="w-20 h-4 bg-brand-gray-2" />
                        </div>
                        <span className="block h-4 bg-brand-gray-2 mb-1" />
                        <span className="block h-4 bg-brand-gray-2" />
                      </div>
                    </div>
                  ))}
                </>
              )}
              {state.isSuccess &&
                state.hasNextPage &&
                !state.isFetchingNextPage && (
                  <div className="flex justify-center items-center py-6">
                    <Button
                      analyticsEvent="notifications_load_more_clicked"
                      variant="link"
                      onClick={() => actions.fetchNextPage()}
                    >
                      {i18n.t("inboxDrawerLoadMoreButton")}
                    </Button>
                  </div>
                )}
            </div>
          </DialogPrimitive.Content>
        </DialogPortal>
      </Dialog>
    </>
  );
}

function NotificationItem({
  notification,
  isMarkingAsRead,
  onRead,
}: {
  notification: Notification;
  isSnoozing: boolean;
  isMarkingAsRead: boolean;
  onRead: () => void;
  onSnooze: () => void;
}) {
  const { i18n } = useLingui();
  return (
    <NotificationRulePreview
      rule={notification.notificationRule}
      showIndicator={!notification.isRead}
      publishedAt={notification.publishedAt}
    >
      {!notification.isRead && (
        <div className="flex gap-6">
          <button
            className="inline-flex items-center gap-2 text-brand-blue-1 hover:text-brand-blue-2 text-sm font-semibold focus:outline-hidden"
            disabled={isMarkingAsRead}
            onClick={() => onRead()}
          >
            {i18n.t("Mark as read")}
            <span
              className={cn("text-brand-gray-3 opacity-0 transition-opacity", {
                "animate-spin opacity-100": isMarkingAsRead,
              })}
            >
              <RefreshCwIcon className="w-4 h-4" />
            </span>
          </button>
        </div>
      )}
    </NotificationRulePreview>
  );
}

export function NotificationItemTitle({
  notificationRule,
}: {
  notificationRule: NotificationRule;
}) {
  const { i18n } = useLingui();
  const levels = useLevels();
  const stationName = parseNotificationStation(notificationRule, levels);

  switch (notificationRule.key) {
    case "no_line_output":
      return (
        <>
          <strong>
            {`${i18n.t("No output")} ${i18n.t("on station")} ${stationName}`}
          </strong>
          <br />
          {i18n.t("No new output for more than {value}", {
            value: parseNotificationRuleTime(notificationRule, i18n),
          })}
        </>
      );
    case "target_output_achieved":
      return (
        <strong>
          {`${i18n.t("Target output")} ${i18n.t("on station")} ${stationName} ${i18n.t("achieved")}`}
        </strong>
      );
    case "unachievable_target_output":
      return (
        <strong>
          {`${i18n.t("Target output")} ${i18n.t("on station")} ${stationName} ${i18n.t("won't be achieved for current shift.")}`}
        </strong>
      );
    case "custom":
    default:
      return (
        <>
          <strong>{parseNotificationRuleType(notificationRule, i18n)}</strong>
          <span>{` ${i18n.t("on station")} `}</span>
          <strong>{stationName}</strong>
          <span>{` ${i18n.t("is")} ${parseNotificationRuleRelation(notificationRule, i18n)} `}</span>
          <strong>
            {parseNotificationRuleThreshold(notificationRule, i18n)}
          </strong>
          <span>{` ${parseNotificationRuleTime(notificationRule, i18n)}`}</span>
        </>
      );
  }
}

export function NotificationRulePreview({
  rule,
  showIndicator,
  publishedAt,
  children,
}: PropsWithChildren<{
  rule: NotificationRule;
  showIndicator: boolean;
  publishedAt: Date;
}>) {
  const locale = localeForDateFns();
  return (
    <div className="relative flex w-full items-start gap-4 py-6">
      <NotificationIcon rule={rule} />
      <div className="flex-1">
        <p className="mb-4">
          <NotificationItemTitle notificationRule={rule} />
        </p>
        {children}
      </div>
      <div className="flex gap-2 items-center mt-0.5 ml-12">
        <span className="text-sm text-brand-gray-4">
          {formatDistance(publishedAt, new Date(), { locale })}
        </span>
        {showIndicator && (
          <span className="w-2.5 h-2.5 inline-block rounded-full bg-brand-blue-1" />
        )}
      </div>
    </div>
  );
}

function NotificationIcon({ rule }: { rule: NotificationRule }) {
  switch (rule.key) {
    case "no_line_output":
    case "unachievable_target_output":
      return (
        <div className="p-1 rounded-full border-2 border-brand-chart-warning/20">
          <div className="p-1 rounded-full border-2 border-brand-chart-warning/50">
            <AlertCircleIcon className="w-7 h-7 text-brand-chart-warning" />
          </div>
        </div>
      );
    case "target_output_achieved":
      return (
        <div className="p-1 rounded-full border-2 border-brand-chart-improving/20">
          <div className="p-1 rounded-full border-2 border-brand-chart-improving/40">
            <CircleCheckBigIcon className="w-7 h-7 text-brand-chart-improving" />
          </div>
        </div>
      );
    case "custom":
    default:
      return (
        <div className="p-1 rounded-full border-2 border-brand-chart-value/20">
          <div className="p-2 rounded-full border-2 border-brand-chart-value/40">
            <AlarmClockIcon className="w-5 h-5 text-brand-chart-value" />
          </div>
        </div>
      );
  }
}

function parseNotificationStation(
  rule: NotificationRule,
  levels: LevelsCtxValue
) {
  const station = levels.stationsByStationId[rule.stationId];
  const line = levels.linesByLineId[station.lineId];
  const lineName = line?.name || `Line #${line?.id}`;
  const stationName = station?.name || `Station #${rule.stationId}`;
  return `${stationName} (${lineName})`;
}

function parseNotificationRuleType(rule: NotificationRule, i18n: I18n) {
  switch (rule.type) {
    case "output":
      return i18n.t("Output");
    case "total_cycle_time":
      return i18n.t("Total cycle time");
    case "avg_cycle_time":
      return i18n.t("Average cycle time");
    case "persons":
      return i18n.t("Persons");
    case "activity":
      return i18n.t("Activity");
    default:
      return rule.type;
  }
}

function parseNotificationRuleRelation(rule: NotificationRule, i18n: I18n) {
  switch (rule.relation) {
    case "gte":
      return i18n.t("greater than");
    case "lte":
      return i18n.t("less than");
    case "eq":
    default:
      return i18n.t("equal to");
  }
}

function parseNotificationRuleThreshold(rule: NotificationRule, i18n: I18n) {
  switch (rule.context.thresholdType) {
    case "absolute":
      return i18n.t("{value}", { value: rule.context.thresholdValue });
    case "percent_of_target":
    default:
      return i18n.t("{value}% of target", {
        value: rule.context.thresholdValue,
      });
  }
}

function parseNotificationRuleTime(rule: NotificationRule, i18n: I18n) {
  const minutes = Math.round(rule.context.periodSeconds / 60);
  return i18n.t("within last {value} minutes", { value: minutes });
}
