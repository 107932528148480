import { I18n } from "@lingui/core";
import { useLingui } from "@lingui/react";
import { useMemo, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  ResponsiveContainer,
  Text,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { StationId } from "@/domain/station";
import { useSelectedProductIds } from "@/view/pages/line-id/use-selected-product-ids";
import { useTags } from "@/view/pages/line-id/use-tags";
import { formatDuration, formatValueAsPercentage } from "@/view/utils";

import { ChartLegend } from "./chart-legend";
import { ChartWrapper } from "./chart-wrapper";
import { getColor, getKeysForChart, getLegendName } from "./helpers";
import { useStationName } from "./use-station-name";

const activityTypes = ["person_product", "person", "product", "empty"] as const;

export type ActivityType = (typeof activityTypes)[number];

type ActivityValue = {
  value: number;
  relativeValue: number;
};
export type ActivityByStationChartData = {
  stationId: StationId;
  products: Record<
    string,
    {
      person_product: ActivityValue;
      person: ActivityValue;
      product: ActivityValue;
      empty: ActivityValue;
    }
  >;
};

const margin = {
  top: 75,
  right: 0,
  left: 60,
  bottom: 100,
};
const tickStyle = {
  fontSize: 11,
  fill: "black",
};

export function ActivityByStationChart({
  data,
  height,
  onBarClick,
}: {
  data: Array<ActivityByStationChartData>;
  height: number;
  onBarClick?: (label: StationId) => void;
}) {
  const { i18n } = useLingui();
  const tagsStore = useTags();
  const getStationName = useStationName();
  const selectedProductIds = useSelectedProductIds();
  const [hiddenLegends, setHiddenLegends] = useState<Array<string>>([]);

  const productKeys = getKeysForChart(selectedProductIds);
  const selectedActivityTypes = useMemo(
    () => activityTypes.filter((it) => !hiddenLegends.includes(it)),
    [hiddenLegends]
  );

  const dataMap = useMemo(() => {
    return data.reduce<Record<StationId, ActivityByStationChartData>>(
      (acc, station) => {
        acc[station.stationId] = station;
        return acc;
      },
      {}
    );
  }, [data]);

  const chartData = useMemo(() => {
    return data.map((station) => {
      const transformed: Record<string, string | ActivityValue> = {
        name: station.stationId,
      };

      for (const productId of productKeys) {
        for (const activityType of selectedActivityTypes) {
          if (station.products[productId]?.[activityType]) {
            transformed[`${productId}-${activityType}`] =
              station.products[productId][activityType];
          }
        }
      }

      return transformed;
    });
  }, [data, productKeys, selectedActivityTypes]);

  const legendItems = activityTypes.map((activityType) => ({
    value: activityType,
    color: getColorForActivity(activityType),
    name: getActivityName(activityType, i18n),
  }));

  // Generate bars for each product and activity type
  const bars = useMemo(() => {
    return productKeys.map((productId) => {
      return selectedActivityTypes.map((activityType) => (
        <Bar
          key={`${productId}-${activityType}`}
          dataKey={`${productId}-${activityType}.relativeValue`}
          stackId={productId}
          name={`${productId} (${activityType})`}
          fill={getColorForActivity(activityType)}
          radius={3}
          style={{ cursor: "pointer" }}
          maxBarSize={250}
          label={({ name, viewBox, ...props }) => {
            if (viewBox.height < 15) return <g />; // Don't show label if bar is too short, null is not an option for TS

            const activityValue =
              dataMap[name as StationId]?.products[productId]?.[activityType];

            const value =
              activityValue && typeof activityValue !== "string"
                ? activityValue.relativeValue
                : 0;

            return (
              <Text
                {...props}
                x={viewBox.x + viewBox.width / 2}
                y={viewBox.y + viewBox.height / 2}
                textAnchor="middle"
                dominantBaseline="middle"
                fontSize={11}
                fill={getTextColorForActivity(activityType)}
              >
                {formatValueAsPercentage(value, 0)}
              </Text>
            );
          }}
        >
          {/* show on top of the bar */}
          {activityType == "empty" && productKeys.length > 1 && (
            <LabelList
              valueAccessor={(entry: Record<string, unknown>) => {
                const key = Object.keys(entry).some(
                  (key) => key == `${productId}-${activityType}`
                );
                if (!key) return null;
                return getLegendName(productId, "single", tagsStore, i18n);
              }}
              content={({ x, y, value, width }) => {
                if (!value) return null;

                return (
                  <Text
                    x={x}
                    y={y}
                    dominantBaseline="middle"
                    fontSize={10}
                    fill="#5D636B"
                    transform={`rotate(-90, ${x}, ${y}) translate(2, ${Number(width) / 2})`}
                  >
                    {value}
                  </Text>
                );
              }}
            />
          )}
        </Bar>
      ));
    });
  }, [dataMap, i18n, tagsStore, selectedActivityTypes, productKeys]);

  return (
    <ChartWrapper
      height={height}
      controls={
        <ChartLegend
          items={legendItems}
          hiddenLegends={hiddenLegends}
          onLegendClick={(legend) =>
            setHiddenLegends((it) => {
              if (it.includes(legend)) {
                return it.filter((l) => l !== legend);
              } else {
                return [...it, legend];
              }
            })
          }
        />
      }
    >
      <ResponsiveContainer>
        <BarChart
          data={chartData}
          margin={margin}
          onClick={(e) => onBarClick?.(e.activeLabel as StationId)}
        >
          <CartesianGrid vertical={false} stroke="#e5eaf0" />
          <XAxis
            dataKey="name"
            tickFormatter={(value) => getStationName(value as StationId)}
            tick={({ payload, tickFormatter, x, y }) => {
              return (
                <text
                  {...tickStyle}
                  textAnchor="end"
                  x={x}
                  y={y + 10}
                  transform={`rotate(-14, ${x}, ${y})`}
                >
                  {tickFormatter(payload.value)}
                </text>
              );
            }}
            axisLine={false}
          />
          <YAxis
            axisLine={false}
            tickFormatter={formatValueAsPercentage}
            tick={tickStyle}
            domain={[0, 1]} // 0 to 100%
            ticks={[0, 0.25, 0.5, 0.75, 1]} // 0, 25, 50, 75, 100%
            label={{
              value: i18n.t("lineOverviewChartTabActivity"),
              style: { textAnchor: "middle", fontSize: 12, fill: "#000" },
              angle: -90,
              position: "left",
              offset: 0,
            }}
          />
          <Tooltip
            cursor={false}
            content={({ active, label }) => (
              <StationDataTooltip
                active={active}
                stationData={dataMap[label as StationId]}
                label={label}
                productIds={productKeys}
                activityTypes={[...selectedActivityTypes].reverse()}
              />
            )}
          />
          {bars}
        </BarChart>
      </ResponsiveContainer>
    </ChartWrapper>
  );
}

function StationDataTooltip({
  active,
  label,
  stationData,
  productIds,
  activityTypes,
}: {
  active: boolean | undefined;
  label: string;
  stationData: ActivityByStationChartData | undefined;
  productIds: Array<string>;
  activityTypes: Array<ActivityType>;
}) {
  const { i18n } = useLingui();
  const getStationName = useStationName();
  const tagsStore = useTags();

  if (!active || !stationData) return null;

  return (
    <div className="bg-white p-4 border rounded-sm shadow-sm">
      <p className="font-bold mb-4 text-center">
        {getStationName(label as StationId)}
      </p>

      <table className="border-separate border-spacing-0">
        <thead>
          <tr>
            <th className="text-left px-2 py-1 border-b font-normal text-sm">
              {i18n.t("Activity")}
            </th>

            {productIds.map((productId) => {
              return (
                <th
                  key={productId}
                  className="text-left px-2 border-b font-normal text-sm py-1 last:pr-0"
                  style={{
                    color: getColor(productId, "compared", tagsStore),
                  }}
                >
                  {getLegendName(productId, "single", tagsStore, i18n)}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {activityTypes.map((activityType) => (
            <tr key={activityType}>
              <td className="text-left px-2 py-1 font-normal text-sm">
                <div className="flex items-center gap-2">
                  <div
                    className="w-2 h-2 rounded-full"
                    style={{
                      backgroundColor: getColorForActivity(activityType),
                    }}
                  />
                  {getActivityName(activityType, i18n)}
                </div>
              </td>

              {productIds.map((productId) => {
                const activityValue =
                  stationData.products[productId]?.[activityType];

                return (
                  <td
                    key={productId}
                    className="text-left p-2 py-1 font-normal text-sm first:pl-0 last:pr-0"
                  >
                    {activityValue ? formatDuration(activityValue.value) : "-"}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function getColorForActivity(key: string) {
  switch (key) {
    case "empty":
      return "#BECADA"; // gray
    case "person":
      return "#e2553f"; // red
    case "product":
      return "#eb942e"; // orange
    case "person_product":
    default:
      return "#4faf6e";
  }
}

function getTextColorForActivity(key: string) {
  switch (key) {
    case "empty":
      return "#5D636B"; // gray
    default:
      return "#fff";
  }
}

function getActivityName(activityType: ActivityType, i18n: I18n) {
  const map = {
    person_product: i18n.t("Person & product at station"),
    person: i18n.t("Only person at station"),
    product: i18n.t("Only product at station"),
    empty: i18n.t("No person & no product"),
  };
  return map[activityType];
}
