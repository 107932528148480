import { useLingui } from "@lingui/react";
import * as Toast from "@radix-ui/react-toast";
import { CheckCircle, XIcon } from "lucide-react";
import { PropsWithChildren, useEffect, useRef, useState } from "react";

import { cn } from "@/view/utils";

export function SuccessFeedback({
  title,
  children,
  open,
  onClose,
}: PropsWithChildren<{
  open: boolean;
  title?: string;
  onClose: () => void;
}>) {
  const { i18n } = useLingui();
  return (
    <Toast.Provider swipeDirection="up">
      <Toast.Root
        className={cn(
          "py-4 px-6 relative border border-brand-gray-3 rounded-md shadow-xs",
          "bg-brand-white text-brand-gray-5",
          "data-[state=open]:animate-slideIn data-[state=closed]:animate-hide",
          "data-[swipe=move]:translate-x-24 data-[swipe=end]:animate-swipeOut",
          "data-[swipe=cancel]:translate-x-0 data-[swipe=cancel]:transition-[transform_200ms_ease-out]"
        )}
        open={open}
        onOpenChange={(open) => {
          if (!open) {
            onClose();
          }
        }}
      >
        <Toast.Title className="flex items-center mb-2">
          <CheckCircle className="h-4 w-4 mr-2 text-brand-improving" />
          <span className="font-bold text-base text-brand-improving">
            {title ?? i18n.t("Success!")}
          </span>
        </Toast.Title>
        <Toast.Description className="text-sm text-brand-gray-5">
          {children}
        </Toast.Description>
        <Toast.Close className="absolute top-0 right-0 p-3 cursor-pointer hover:bg-brand-neutral-shade transition-colors">
          <XIcon className="h-4 w-4" />
        </Toast.Close>
      </Toast.Root>
      <Toast.Viewport className="fixed bottom-0 right-0 p-10 max-h-full flex flex-col z-2147483647 outline-hidden" />
    </Toast.Provider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export function useShowSuccessFeedback() {
  const [open, setOpen] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const ref = timerRef.current;
    return () => {
      if (ref) clearTimeout(ref);
    };
  }, []);

  function showSuccessFeedback() {
    setOpen(false);
    if (timerRef.current) clearTimeout(timerRef.current);

    timerRef.current = setTimeout(() => {
      setOpen(true);
    }, 100);
  }

  function hideSuccessFeedback() {
    setOpen(false);
    if (timerRef.current) clearTimeout(timerRef.current);
  }

  return {
    open,
    showSuccessFeedback,
    hideSuccessFeedback,
  };
}
