import { QueryClient } from "@tanstack/react-query";
import { FC, lazy } from "react";
import {
  createBrowserRouter,
  generatePath,
  Navigate,
  RouteObject,
} from "react-router-dom";

import { createGraphQlApiClient } from "@/infra/graphql-api-client";
import {
  createSentryTracingClient,
  routerTracingWrapper,
} from "@/infra/sentry-tracing";
import { useSelectedLine } from "@/view/pages/line-id/use-selected-line";
import { LineBirdEyeViewAreaDetails } from "@/view/pages/line-id-area-id";
import { LineBirdEyeViewStationDetails } from "@/view/pages/line-id-bev-area-id-station-id";
import { LineBirdEyeViewStationDetailsOverview } from "@/view/pages/line-id-bev-area-id-station-id-overview";
import { StationVideos } from "@/view/pages/line-id-reporting-station-id/station-videos";
import { useUserGroups } from "@/view/providers/use-user-groups";

import { ErrorPage, LineCatchAllPage, LineRootPage } from "./pages/line-id";
import { LineBirdEyeViewPage } from "./pages/line-id-bev";
import { LineReportingPage } from "./pages/line-id-reporting";
import { StationDetailsPage } from "./pages/line-id-reporting-station-id";
import {
  LineSettingsIndexPage,
  LineSettingsRootPage,
} from "./pages/line-id-settings";
import { LineNotificationSettingsPage } from "./pages/line-id-settings-notifications";
import { LineSettingsShiftsPage } from "./pages/line-id-settings-shifts";
import { LineVideoLibraryPage } from "./pages/line-id-video-library";
import { every, GuardContext, isAdmin, isManualLine } from "./utils";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      retry: false,
    },
  },
});

export const tracingClient = createSentryTracingClient();
export const apiClient = createGraphQlApiClient(tracingClient);

export const paths = {
  root: "/",
  linePath: "/line/:lineId",
  lineReportingPath: "/line/:lineId/reporting",
  lineReportingStationDetailsPath: "/line/:lineId/reporting/station/:stationId",

  lineBirdEyeViewPath: "/line/:lineId/bev",
  lineBirdEyeViewAreaDetailsPath: "/line/:lineId/bev/area/:areaId",
  lineBirdEyeViewStationDetailsPath:
    "/line/:lineId/bev/area/:areaId/station/:stationId",
  lineBirdEyeViewStationDetailsOverviewPath:
    "/line/:lineId/bev/area/:areaId/station/:stationId/overview",
  lineBirdEyeViewStationDetailsVideosPath:
    "/line/:lineId/bev/area/:areaId/station/:stationId/videos",

  lineTrendsPath: "/line/:lineId/trends",
  lineVideoLibraryPath: "/line/:lineId/video-library",
  lineVideoLibraryVideoIdPath: "/line/:lineId/video-library/:videoId",
  lineSettingsPath: "/line/:lineId/settings",
  lineSettingsShiftsPath: "/line/:lineId/settings/shifts",
  lineSettingsNotificationsPath: "/line/:lineId/settings/notifications",

  lineCycleDebuggingPath: "/line/:lineId/cycle-debugging",
  lineCamerasSetupPath: "/line/:lineId/cameras",
  lineCameraAreasEditorPath: "/line/:lineId/cameras/:cameraId",
} as const;

const LineCycleDebuggingPage = lazy(() =>
  import("./pages/line-id-cycle-debugging").then((module) => ({
    default: module.LineCycleDebuggingPage,
  }))
);

const LineCamerasSetupRootPage = lazy(() =>
  import("./pages/line-id-cameras-setup").then((module) => ({
    default: module.LineCamerasSetupRootPage,
  }))
);

const LineCamerasSetupPage = lazy(() =>
  import("./pages/line-id-cameras-setup").then((module) => ({
    default: module.LineCamerasSetupPage,
  }))
);

const LineCameraAreasEditorPage = lazy(() =>
  import("./pages/line-id-cameras-setup-area-id").then((module) => ({
    default: module.LineCameraAreasEditorPage,
  }))
);

const LineTrendsPage = lazy(() =>
  import("./pages/line-id-trends").then((module) => ({
    default: module.LineTrendsPage,
  }))
);

// eslint-disable-next-line react-refresh/only-export-components
const ProtectedRoute: FC<{
  guard: (context: GuardContext) => boolean;
  children: React.ReactNode;
}> = ({ guard, children }) => {
  const line = useSelectedLine();
  const userGroups = useUserGroups();

  if (!userGroups) {
    return null;
  }

  if (guard({ line, userGroups })) {
    return children;
  }

  return (
    <Navigate
      to={generatePath(paths.lineReportingPath, { lineId: line.id })}
      replace
    />
  );
};

const VideoPage = lazy(() =>
  import("./pages/videos-id").then((module) => ({
    default: module.VideoPage,
  }))
);

const routes: Array<RouteObject> = [
  {
    element: <LineRootPage />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: paths.lineReportingPath,
        element: <LineReportingPage />,
        children: [
          {
            path: paths.lineReportingStationDetailsPath,
            element: <StationDetailsPage />,
          },
        ],
      },
      {
        path: paths.lineBirdEyeViewPath,
        element: (
          <ProtectedRoute guard={isManualLine}>
            <LineBirdEyeViewPage />
          </ProtectedRoute>
        ),
        children: [
          {
            path: paths.lineBirdEyeViewAreaDetailsPath,
            element: <LineBirdEyeViewAreaDetails />,
          },
          {
            path: paths.lineBirdEyeViewStationDetailsPath,
            element: <LineBirdEyeViewStationDetails />,
            children: [
              {
                path: paths.lineBirdEyeViewStationDetailsOverviewPath,
                element: <LineBirdEyeViewStationDetailsOverview />,
              },
              {
                path: paths.lineBirdEyeViewStationDetailsVideosPath,
                element: <StationVideos />,
              },
            ],
          },
        ],
      },
      {
        path: paths.lineTrendsPath,
        element: (
          <ProtectedRoute guard={every(isAdmin, isManualLine)}>
            <LineTrendsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: paths.lineVideoLibraryPath,
        element: <LineVideoLibraryPage />,
      },
      {
        path: paths.lineVideoLibraryVideoIdPath,
        element: <VideoPage />,
      },
      {
        path: paths.lineSettingsPath,
        element: <LineSettingsRootPage />,
        children: [
          {
            index: true,
            element: <LineSettingsIndexPage />,
          },
          {
            path: paths.lineSettingsShiftsPath,
            element: <LineSettingsShiftsPage />,
          },
          {
            path: paths.lineSettingsNotificationsPath,
            element: <LineNotificationSettingsPage />,
          },
        ],
      },
      {
        path: paths.lineCycleDebuggingPath,
        element: (
          <ProtectedRoute guard={isAdmin}>
            <LineCycleDebuggingPage />
          </ProtectedRoute>
        ),
      },
      {
        path: paths.lineCamerasSetupPath,
        element: (
          <ProtectedRoute guard={isAdmin}>
            <LineCamerasSetupRootPage />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: <LineCamerasSetupPage />,
          },
          {
            path: paths.lineCameraAreasEditorPath,
            element: <LineCameraAreasEditorPage />,
          },
        ],
      },
      {
        path: "*", // Catch all
        element: <LineCatchAllPage />,
      },
    ],
  },
];

const createRouter = routerTracingWrapper(createBrowserRouter);
export const router = createRouter(routes);
