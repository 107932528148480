import { useLingui } from "@lingui/react";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import { ArrowLeftIcon } from "lucide-react";
import { PropsWithChildren } from "react";
import { generatePath, NavLink } from "react-router-dom";

import { paths } from "@/view/routes";
import { cn } from "@/view/utils";

import { useSelectedLine } from "../line-id/use-selected-line";

export function SettingsNav() {
  const { i18n } = useLingui();
  const line = useSelectedLine();
  return (
    <NavigationMenu.Root>
      <NavigationMenu.List className="flex gap-2">
        <MenuItem
          href={generatePath(paths.lineReportingPath, { lineId: line.id })}
        >
          <ArrowLeftIcon />
          {i18n.t("Go Back To Reporting")}
        </MenuItem>
        <MenuItem
          href={generatePath(paths.lineSettingsShiftsPath, { lineId: line.id })}
        >
          {i18n.t("Shifts & Targets")}
        </MenuItem>
        <MenuItem
          href={generatePath(paths.lineSettingsNotificationsPath, {
            lineId: line.id,
          })}
        >
          {i18n.t("Notifications")}
        </MenuItem>
      </NavigationMenu.List>
    </NavigationMenu.Root>
  );
}

function MenuItem({ href, children }: PropsWithChildren<{ href: string }>) {
  return (
    <NavigationMenu.Item>
      <NavigationMenu.Link asChild>
        <NavLink to={href} className="focus:outline-hidden">
          {({ isActive }) => (
            <span
              className={cn(
                "flex gap-2 px-4 py-2 rounded-md",
                "text-base text-brand-gray-5 font-semibold",
                "hover:bg-brand-gray-1",
                {
                  "text-brand-blue-1": isActive,
                }
              )}
            >
              {children}
            </span>
          )}
        </NavLink>
      </NavigationMenu.Link>
    </NavigationMenu.Item>
  );
}
