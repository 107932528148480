import { I18n } from "@lingui/core";
import { useLingui } from "@lingui/react";
import { PropsWithChildren } from "react";

import { Card } from "@/view/components/card";
import { Tabs, TabsList, TabsTrigger } from "@/view/components/tabs";
import { cn } from "@/view/utils";

import { useLineReportingFilterActions } from "./line-reporting-filters-provider";
import {
  ChartType,
  chartTypes,
  useSelectedChartType,
} from "./use-selected-line-chart";

export function LineReportingTabs({ children }: PropsWithChildren) {
  const selectedTab = useSelectedChartType();
  return (
    <>
      <Card className="border shadow-xs">
        <Tabs value={selectedTab} className="w-full flex flex-col">
          <TabsList className="justify-start">
            {chartTypes.map((type) => (
              <TabItem key={type} type={type} />
            ))}
          </TabsList>
          {children}
        </Tabs>
      </Card>
    </>
  );
}

function TabItem({ type }: { type: ChartType }) {
  const { i18n } = useLingui();
  const actions = useLineReportingFilterActions();
  return (
    <TabsTrigger
      className={cn(
        "px-8 py-4 border-b-2 border-transparent",
        "data-[state=active]:bg-brand-neutral-shade",
        "data-[state=active]:text-brand-blue-1",
        "data-[state=active]:border-brand-blue-1"
      )}
      value={type}
      onClick={() => actions.selectChartType(type)}
    >
      {getTabTitle(type, i18n)}
    </TabsTrigger>
  );
}

function getTabTitle(type: ChartType, i18n: I18n) {
  switch (type) {
    case "output_by_time":
      return i18n.t("lineOverviewChartTabOutputByTime");
    case "output":
      return i18n.t("lineOverviewChartTabOutput");
    case "avg_cycle_time":
      return i18n.t("lineOverviewChartTabAvgCycleTime");
    case "activity":
      return i18n.t("lineOverviewChartTabActivity");
  }
}
