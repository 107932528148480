import { useLingui } from "@lingui/react";
import { useQuery } from "@tanstack/react-query";

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/view/components";
import { LineWidgetLoading } from "@/view/pages/line-id-reporting/line-widget-loading";
import { useApiClient } from "@/view/providers/api-client-provider";
import { formatKpiValue } from "@/view/utils";

import { useLineStatisticsParams } from "./line-statistics-provider";
import { LineWidgetDropdown } from "./line-widget-dropdown";

function LineWidgetAverageNumberOfWorkersError() {
  const { i18n } = useLingui();
  return (
    <Card className="p-4 h-44 grow border rounded-xl shadow-xs">
      <CardHeader>
        <CardTitle>{i18n.t("Average number of people in the line")}</CardTitle>
      </CardHeader>
      <CardContent>
        <CardDescription className="whitespace-nowrap">
          <span className="text-5xl">--</span>
          <span className="text-2xl">/--</span>
        </CardDescription>
      </CardContent>
    </Card>
  );
}

export function LineWidgetAverageNumberOfWorkers() {
  const { i18n } = useLingui();

  const apiClient = useApiClient();
  const [lineId, shiftIds, tagIds, dateRange, productIds, stationIds] =
    useLineStatisticsParams();
  const { data, isLoading } = useQuery({
    queryKey: [
      "line-average-number-of-workers",
      lineId,
      dateRange,
      shiftIds,
      tagIds,
      productIds,
      stationIds,
    ],
    queryFn: () =>
      apiClient.getLineAverageNumberOfWorkers({
        lineId: lineId.id,
        dateRange,
        shiftIds,
        tagIds,
        productIds,
        stationIds,
      }),
  });

  if (data) {
    const formattedValue = formatKpiValue("workers", data.value);
    const formattedTarget = formatKpiValue("workers", data.target);
    const isTargetAvailable = data.target > 0;

    return (
      <Card className="p-4 border rounded-xl shadow-xs h-44 min-w-min">
        <CardHeader className="relative">
          <CardTitle>
            {i18n.t("Average number of people in the line")}
          </CardTitle>
          <div className="absolute top-2 right-0">
            <LineWidgetDropdown targetType="persons" />
          </div>
        </CardHeader>
        <CardContent>
          <CardDescription className="font-bold whitespace-nowrap">
            <span className="text-5xl">{formattedValue}</span>
            {isTargetAvailable && (
              <span className="text-2xl">/{formattedTarget}</span>
            )}
          </CardDescription>
        </CardContent>
      </Card>
    );
  }

  if (isLoading) {
    return <LineWidgetLoading />;
  }

  return <LineWidgetAverageNumberOfWorkersError />;
}
