import { I18n } from "@lingui/core";
import { useLingui } from "@lingui/react";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  CheckCircle2Icon,
  LucideIcon,
} from "lucide-react";

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/view/components";
import { cn, formatKpiValue } from "@/view/utils";

import { useLineStatistics } from "./line-statistics-provider";
import { Badge } from "./line-widget-badge";
import { LineWidgetDropdown } from "./line-widget-dropdown";

export function LineWidgetOutputError() {
  const { i18n } = useLingui();
  return (
    <Card className="p-4 h-44 grow border rounded-xl shadow-xs">
      <CardHeader>
        <CardTitle>{i18n.t("lineOverviewWidgetOutputTitle")}</CardTitle>
      </CardHeader>
      <CardContent>
        <CardDescription className="whitespace-nowrap">
          <span className="text-5xl">--</span>
          <span className="text-2xl">/--</span>
        </CardDescription>
      </CardContent>
    </Card>
  );
}

export function LineWidgetOutput() {
  const { i18n } = useLingui();
  const data = useLineStatistics();

  const formattedValue = formatKpiValue("output", data.output.value);
  const formattedTarget = formatKpiValue("output", data.output.target);
  const isTargetAvailable = data.output.target > 0;
  const percentage = isTargetAvailable
    ? (data.output.value / data.output.target) * 100
    : 0;
  const [Icon, text, variant] = outputBadgeContent(percentage, i18n);

  return (
    <Card className="p-4 border rounded-xl shadow-xs h-44 min-w-min">
      <div className="flex justify-between gap-2 pr-8 relative">
        <div>
          <CardHeader className="flex-row gap-10 items-center space-y-0">
            <CardTitle>{i18n.t("lineOverviewWidgetOutputTitle")}</CardTitle>
            {isTargetAvailable && (
              <Badge variant={variant}>
                <Icon className="mr-1" size={16} />
                {text}
              </Badge>
            )}
          </CardHeader>
          <CardContent>
            <CardDescription className="font-bold whitespace-nowrap">
              <span className="text-5xl">{formattedValue}</span>
              {isTargetAvailable && (
                <span className="text-2xl">/{formattedTarget}</span>
              )}
            </CardDescription>
          </CardContent>
        </div>
        {isTargetAvailable && (
          <RingProgress
            progress={percentage}
            className="block xl:hidden 2xl:block"
          />
        )}
        <div className="absolute top-2 right-0">
          <LineWidgetDropdown targetType="output" />
        </div>
      </div>
    </Card>
  );
}

function RingProgress({
  strokeWidth = 12,
  radius = 70,
  progress,
  className,
}: {
  strokeWidth?: number;
  radius?: number;
  progress: number;
  className?: string;
}) {
  const normalizedRadius = radius - strokeWidth;
  const circumference = normalizedRadius * 2 * Math.PI;
  const progressClamped = Math.min(Math.max(progress, 0), 100);
  const strokeDashoffset =
    circumference - (progressClamped / 100) * circumference;

  return (
    <div className={cn("relative self-start", className)}>
      <svg height={radius * 2} width={radius * 2} aria-hidden="true">
        <circle
          className="text-brand-gray-2"
          fill="transparent"
          stroke="currentColor"
          strokeWidth={strokeWidth}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <circle
          className="text-brand-blue-1 -rotate-90 transform origin-center"
          fill="transparent"
          stroke="currentColor"
          strokeWidth={strokeWidth}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={strokeDashoffset}
          strokeLinecap="round"
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
      <h3
        className={cn(
          "absolute top-0 left-0 z-0",
          "w-full h-full justify-center items-center flex",
          "text-2xl font-bold text-brand-black",
          "select-none"
        )}
      >{`${formatKpiValue("activity", progress)}%`}</h3>
    </div>
  );
}

function outputBadgeContent(
  percentage: number,
  i18n: I18n
): [icon: LucideIcon, text: string, variant: "success" | "warning" | "error"] {
  if (percentage > 100) {
    return [
      ArrowUpIcon,
      i18n.t("lineOverviewWidgetOutputBadgeAboveTarget", {
        value: `${(percentage - 100).toFixed(2)}%`,
      }),
      "success",
    ];
  }
  if (percentage === 100) {
    return [
      CheckCircle2Icon,
      i18n.t("lineOverviewWidgetOutputBadgeTargetReached"),
      "success",
    ];
  }
  if (percentage >= 90) {
    return [
      ArrowDownIcon,
      i18n.t("lineOverviewWidgetOutputBadgeBelowTarget", {
        value: `${(100 - percentage).toFixed(2)}%`,
      }),
      "warning",
    ];
  }
  return [
    ArrowDownIcon,
    i18n.t("lineOverviewWidgetOutputBadgeBelowTarget", {
      value: `${(100 - percentage).toFixed(2)}%`,
    }),
    "error",
  ];
}
